<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4'>
   
      <b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
          <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/currencyQuotes/display'}">
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>

          <div class='card'>
            <div class='bg-primary p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Edit  New Currency Quotes</h5>
            </div>
          
            <div class='card-body'>
              <b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
								</b-col>
							</b-row>
              <b-row>
              <b-col lg="6">
                <b-form-group label="Base Currency *">
                  <multiselect
                    v-model="form.base_currency"
                    label="iso4217_code"
                    track-by="id"
                    placeholder="Base Currency"
                    open-direction="bottom"
                    :options="CounterCurrencySearchOptions"
                    :searchable="true"
                    :loading="isCounterCurrencySearch"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="false"
                    :preselect-first="false"
                    :multiple="false"
                    :taggable="false"
                    @search-change="setOptionCounterCurrency('base')"
                    :required=true
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Counter Currency *">
                  <multiselect
                    v-model="form.counter_currency"
                    label="iso4217_code"
                    track-by="iso4217_code"
                    placeholder="Counter Currency"
                    open-direction="bottom"
                    :options="CounterCurrencySearchOptions"
                    :searchable="true"
                    :loading="isCounterCurrencySearch"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="false"
                    :preselect-first="false"
                    :multiple="false"
                    :taggable="false"
                    @search-change="setOptionCounterCurrency('counter')"
                    :required=true
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group label='Source *'>
                  <div v-if=!this.isOthers>
                    <b-select id="source"    v-model="form.source" @input="checkOthers()" :options="SourceTypeOption" required>
                    </b-select>
                  </div>
                  <div v-else>
                    <b-form-input id='name' v-model='form.source'  step='any'
                      required>
                    </b-form-input>
                    <b-button variant="primary"  class="btn btn-sm btn-dark col-sm-auto"
                    type="button"  @click="resetOther">Reset Sources</b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col lg="6">
              <b-form-group label='Quote *'>
                <b-form-input id='quote' v-model='form.quote'  step='any' type ="number"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

        <b-row>
          <div class="col-md-6">
            <b-form-group id="input-validFrom" label="Valid From"  label-for="validFrom">
              <b-form-datepicker today-button id="validFrom" :min="form.min" v-model="form.valid_from" class="mb-2"></b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group id="input-validFrom" label="Valid To"  label-for="validFrom">
              <b-form-datepicker today-button id="validTo" :min="form.min" v-model="form.valid_to" class="mb-2"></b-form-datepicker>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <label></label>
          <div class="col-md-4">
            <b-button variant='primary' type='submit'>Edit Currency Quotes</b-button>
          </div>
        </b-row>
      </div>
    </div>
  </b-form>
</b-col>
</b-row>
</b-overlay>
</template>

<script>
import moment from "moment"
import { mapState, mapActions } from 'vuex';
import constant from "../../store/constant";
export default {
  name: 'editCurrencyQuotes',
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today);
    return {
      min: minDate,
      debounce: null,
      id: null,
      isOthers:false,
      form: {
        base_currency:null,
        counter_currency:null,
        source:null,
        quote:null,
        valid_from:'',
        valid_to:'',

      },range: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
      },
      masks: {
          input: "YYYY-MM-DD"
      },
      isBaseCurrencySearch: false,
      isCounterCurrencySearch: false,
      SourceTypeOption: [
				{ value: constant.SOURCE_CURRENCY.GOOGLE.toLowerCase(), text: "Google" },
        { value: constant.SOURCE_CURRENCY.YAHOO.toLowerCase(), text: "Yahoo" },
        { value: constant.SOURCE_CURRENCY.FINANCE_ADMIN.toLowerCase(), text: "Finance Admin" },
        { value: constant.SOURCE_CURRENCY.OTHERS.toLowerCase(), text: "Others" },
      ],
      BaseCurrencySearchOptions: [], 
      CounterCurrencySearchOptions: [],
    };
  },
  computed: {
    ...mapState({
      totalRows: (state) => state.currencyQuotes.totalRows,
      item_id :(state) => state.currencyQuotes.items,
      isLoading : (state) => state.currencyQuotes.isLoading,
      isError : (state) => state.currencyQuotes.isError,
      errorMessage: (state) => state.currencyQuotes.errorMessage,
      successMessage: (state) => state.currencyQuotes.successMessage,
      refresh: (state) => state.currencyQuotes.refresh,
    }),
  },
  watch: {
    item_id : function(){
      if(!this.item_id){
       return 
      }else{
        this.setEditCurrency();
      }
      
    },
    successMessage: function() {
      if (!this.successMessage) return;
      if(this.isError) {
        this.messageAlert('error', this.successMessage);
      }else{
        this.messageAlert('success', this.successMessage);
      }this.$router.push({
        path: `/currencyQuotes/display`,
      })
    },
  },
  created() {
    this.setOptionCounterCurrency('all');
    const id=this.$route.params.id;
    this.id = id;
    const payload = {
      id: id,
    }
    this.getCurrencyQuotesByIdHome(payload);
  },
  mounted() {},
  methods: {
  ...mapActions("currencyQuotes", ["getCurrencyQuotesByIdHome","updateCurrencyQuotes"]),
  ...mapActions("currency", ["fetchCurrency","AutocompleteSearch"]),
  checkOthers(){
    let sources = this.form.source;
    if(sources == constant.SOURCE_CURRENCY.OTHERS.toLowerCase()){
      this.isOthers = true;
    } else{
      this.isOthers = false;
    }
  },resetOther(){
    this.isOthers = false;
  },onSubmit(event) {
    event.preventDefault();
		const data = this.form;
    const d1 = new Date(data.valid_from);
    const d2 = new Date(data.valid_to);
    if(d1>d2) {
      this.messageAlert('error', 'Valid To Should Be Bigger Than Valid From');
      return ;
    }
    if(data.base_currency == null){
      this.messageAlert('error', 'Base Currency Must Be Inputed');
      return ;
    }
    if(data.counter_currency == null){
      this.messageAlert('error', 'Counter Currency Must Be Inputed');
      return ;
    }
    const payload = {
      id: this.id,
      base_currency: data.base_currency.iso4217_code,
      counter_currency:data.counter_currency.iso4217_code,
      source:data.source,
      quote:data.quote,
      valid_from : data.valid_from,
      valid_to : data.valid_to,
    }
    this.updateCurrencyQuotes(payload);
  },async setOptionCounterCurrency(type){
    if(type=='base'|| type=='all'){
      this.isBaseCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.fetchCurrency({ page: 1, limit: 100 })
					.then((response) => {
						this.BaseCurrencySearchOptions = response.data.data.rows;
						this.isBaseCurrencySearch = false;
					})
					.catch(() => {
						this.isBaseCurrencySearch = false;
					});
			}, 1200);
    } if(type=='counter' || type == 'all'){
      this.isCounterCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.fetchCurrency({ page: 1, limit: 100 })
					.then((response) => {
						this.CounterCurrencySearchOptions = response.data.data.rows;
						this.isCounterCurrencySearch = false;
					})
					.catch(() => {
						this.isCounterCurrencySearch = false;
					});
			}, 1200);
    }
  },onReset() {},
    messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
  },setEditCurrency() {
      const data = this.item_id;
      const payloadBaseCurrency = {
        q:data.base_currency,
      };
      const payloadCounterCurrency = {
        q:data.counter_currency,
      };
    
      this.AutocompleteSearch(payloadBaseCurrency).then((response) => {
        this.form.base_currency =  response.data?.data?.rows[0];
        this.BaseCurrencySearchOptions = response.data.data.rows;
      });
      this.AutocompleteSearch(payloadCounterCurrency).then((response) => {
        this.form.counter_currency =  response.data?.data?.rows[0];
        this.CounterCurrencySearchOptions = response.data.data.rows;
      });
      this.form.valid_from = moment(data.valid_from).format("YYYY-MM-DD");
      this.form.valid_to =  moment(data.valid_to).format("YYYY-MM-DD"); 
      this.form.quote = data.quote;
      let i =0;
      let source = null;
      let entries = Object.entries(constant.SOURCE_CURRENCY);
      let dataMap= entries.map( ([val] ) => {
        return val;
      });
      while (i<dataMap.length){
        if(dataMap[i].toLowerCase()==data.source){
          this.isOthers = false;
          this.form.source= data.source;
          source = data.source;
          break;
          
        }
        i++;
      }
      if(!source){
        this.isOthers = true;  
        source = this.item_id.source;
        this.form.source = this.item_id.source;
      }
    },nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
  },reset() {
      Object.assign(this.$data, this.$options.data.call(this));
  },
  }
};

</script>
