var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit,
      "reset": _vm.onReset
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-dark",
    attrs: {
      "size": "sm",
      "to": {
        path: '/currencyQuotes/display'
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward btn-sm btn-dark"
  }), _vm._v(" Back ")]), _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bg-primary p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v("Edit New Currency Quotes")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "bg-info text-dark"
  }, [_vm._v("(*) Kolom berlabel bintang wajib diisi.")])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Base Currency *"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "iso4217_code",
      "track-by": "id",
      "placeholder": "Base Currency",
      "open-direction": "bottom",
      "options": _vm.CounterCurrencySearchOptions,
      "searchable": true,
      "loading": _vm.isCounterCurrencySearch,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": false,
      "preselect-first": false,
      "multiple": false,
      "taggable": false,
      "required": true
    },
    on: {
      "search-change": function ($event) {
        return _vm.setOptionCounterCurrency('base');
      }
    },
    model: {
      value: _vm.form.base_currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "base_currency", $$v);
      },
      expression: "form.base_currency"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Counter Currency *"
    }
  }, [_c('multiselect', {
    attrs: {
      "label": "iso4217_code",
      "track-by": "iso4217_code",
      "placeholder": "Counter Currency",
      "open-direction": "bottom",
      "options": _vm.CounterCurrencySearchOptions,
      "searchable": true,
      "loading": _vm.isCounterCurrencySearch,
      "close-on-select": true,
      "clear-on-select": false,
      "preserve-search": false,
      "preselect-first": false,
      "multiple": false,
      "taggable": false,
      "required": true
    },
    on: {
      "search-change": function ($event) {
        return _vm.setOptionCounterCurrency('counter');
      }
    },
    model: {
      value: _vm.form.counter_currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "counter_currency", $$v);
      },
      expression: "form.counter_currency"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Source *"
    }
  }, [!this.isOthers ? _c('div', [_c('b-select', {
    attrs: {
      "id": "source",
      "options": _vm.SourceTypeOption,
      "required": ""
    },
    on: {
      "input": function ($event) {
        return _vm.checkOthers();
      }
    },
    model: {
      value: _vm.form.source,
      callback: function ($$v) {
        _vm.$set(_vm.form, "source", $$v);
      },
      expression: "form.source"
    }
  })], 1) : _c('div', [_c('b-form-input', {
    attrs: {
      "id": "name",
      "step": "any",
      "required": ""
    },
    model: {
      value: _vm.form.source,
      callback: function ($$v) {
        _vm.$set(_vm.form, "source", $$v);
      },
      expression: "form.source"
    }
  }), _c('b-button', {
    staticClass: "btn btn-sm btn-dark col-sm-auto",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": _vm.resetOther
    }
  }, [_vm._v("Reset Sources")])], 1)])], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Quote *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "quote",
      "step": "any",
      "type": "number",
      "required": ""
    },
    model: {
      value: _vm.form.quote,
      callback: function ($$v) {
        _vm.$set(_vm.form, "quote", $$v);
      },
      expression: "form.quote"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-validFrom",
      "label": "Valid From",
      "label-for": "validFrom"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-2",
    attrs: {
      "today-button": "",
      "id": "validFrom",
      "min": _vm.form.min
    },
    model: {
      value: _vm.form.valid_from,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid_from", $$v);
      },
      expression: "form.valid_from"
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-validFrom",
      "label": "Valid To",
      "label-for": "validFrom"
    }
  }, [_c('b-form-datepicker', {
    staticClass: "mb-2",
    attrs: {
      "today-button": "",
      "id": "validTo",
      "min": _vm.form.min
    },
    model: {
      value: _vm.form.valid_to,
      callback: function ($$v) {
        _vm.$set(_vm.form, "valid_to", $$v);
      },
      expression: "form.valid_to"
    }
  })], 1)], 1)]), _c('b-row', [_c('label'), _c('div', {
    staticClass: "col-md-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v("Edit Currency Quotes")])], 1)])], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }